let buffer = [];
const bufferSize = 1000;
const timer = 60000;
let config = null;
let timeOut = null;

addEventListener('message', ({data}) => {
	if (data?.initialBundle) {
		config = data?.initialBundle
		timeOut = setTimeout(function flushlogs() {
			if (buffer?.length) {
				pushToParseable(buffer)
				buffer = []
			}
			timeOut = setTimeout(flushlogs, (config?.LOG_TIMEOUT || timer))
		}, (config?.LOG_TIMEOUT || timer))
		return;
	}

	const log = {
		datetime: data?.datetime || '',
		'user-identifier': data['user-identifier'] || '',
		type: data?.type || '',
		"username": data?.username || '',
		"message": data?.message || '',
		"metaData": data?.metaData || ''
	}

	if (log.datetime) {
		buffer.push(log)
	}

	if (buffer.length >= (config?.BUFFER_SIZE || bufferSize)) {
		pushToParseable(buffer)
		buffer = []
	}
})


async function pushToParseable(buffer) {
	let requestOptions = {
		method: 'POST',
		mode: 'no-cors',
		headers: {
			'Accept': 'application/json',
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(buffer)
	};
	fetch(`${config?.MONIEPOINT_URL}/log`, requestOptions)
		.then(response => response.text())
		.then(result => console.log('result', result))
		.catch(error => console.log('error', error));
}
