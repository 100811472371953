

 onScroll = (e) => {
	if(e.target.classList){
		e.target.classList.add("scrolling");
		e.target.classList.add("ovl");
	}
	clearTimeout(window.scrollEndTimer);
	window.scrollEndTimer = setTimeout(() => {
		onScrollEnd(e);
	}, 1000)
}

 onScrollEnd = (e) => {
	 setTimeout(()=> {
		if(e.target.classList){
			e.target.classList.remove("scrolling");
		}
	})
}

const style = document.createElement('style');

style.textContent = `
	::-webkit-scrollbar {
		display: block;
		width: 16px;
 	 }

	::-webkit-scrollbar-button {
		display: none;
	}

	::-webkit-scrollbar-track {
		background-color: #00000000;
	}

	::-webkit-scrollbar-track-piece {
		background-color: #00000000;
	}

	::-webkit-scrollbar-thumb {
		transition: background-color 0.5s;
		background-color: #00000000;
		border: 5px solid transparent;
		border-radius: 24px;
		box-shadow: 4px 0px 0px 4px var(--scrollbar-color) inset;
	}

	::-webkit-scrollbar-thumb:hover {
		background-color: var(--scrollbar-color);
		border: 0px solid transparent;
		box-shadow: none;
	}
	body {
		--scrollbar-color: #00000000;
	}
`;


if (navigator.userAgent.indexOf("Win")!=-1) {
	document.head.appendChild(style);
	document.addEventListener('scroll', onScroll, true);
	document.addEventListener('scrollend', onScrollEnd, true);
}
