function logoutFromZenDesk() {
	zE('messenger', 'logoutUser');
}

function loginZendesk(token) {
	if (token) {
		zE('messenger', 'loginUser', function (callback) {
			callback(token);
		});
	}
}

function closeZendeskWidget() {
	zE('messenger', 'close');
}

function isMoniepointAppUser() {
	const userAgent = window.navigator.userAgent.toLowerCase();
	return /moniepointmobile/.test(userAgent);
}

function enableTopSafeArea(enableTopSafeArea) {
	if (!isMoniepointAppUser()) {
		return;
	}
	try {
		window[`flutter_inappwebview`]
			?.callHandler('enableTopSafeArea', enableTopSafeArea)
			.then(() => {})
			.catch((error) => {
				console.log('Error calling enableTopSafeArea delegate ', error);
			});
	} catch (err) {
		console.log('Error enableTopSafeArea function ', err);
	}
}

function openWidget(token) {
	loginZendesk(token);
	enableTopSafeArea(true);
	zE('messenger', 'open');
	zE('messenger:on', 'close', function () {
		enableTopSafeArea(false);
	});
}
